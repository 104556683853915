import { ISelectOption } from '@locumsnest/components/src/lib/interfaces';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IPermissionEntity } from '../../interfaces/api/permission-entity';
import {
  IPermissionEntityState,
  IPermissionFeatureState,
  IPermissionSharedState,
} from './interfaces';
import { loadingAdapter, paginationAdapter } from './permission.adapter';
import { selectAll } from './permission.reducer';

export const getEntityState = (state: { entityState: IPermissionEntityState }) => state.entityState;

export const getPermissionOptions = (permissions: IPermissionEntity[]): ISelectOption[] =>
  permissions.map((permission) => ({ id: permission.id, name: permission.name }));

/**
 * Create Feature Selectors based on the above selectors
 */
export const selectPermissionState =
  createFeatureSelector<IPermissionFeatureState & IPermissionSharedState>('permissions');
export const selectPermissionEntityState = createSelector(selectPermissionState, getEntityState);

export const selectAllPermissions = createSelector(selectPermissionEntityState, selectAll);

export const selectPermissionOptions = createSelector(selectAllPermissions, getPermissionOptions);

export const permissionPaginationSelectors = paginationAdapter.paginationSelectors(
  selectPermissionEntityState,
  loadingAdapter
);
