import { Injectable } from '@angular/core';

import { HttpApiPersistenceService } from '@locumsnest/core/src';

import { LocumsNestEndpointConfig } from '../../core/constants';
import { IPermissionEntity } from '../../interfaces/api/permission-entity';

@Injectable({
  providedIn: 'root',
})
export class PermissionPersistenceService extends HttpApiPersistenceService<
  LocumsNestEndpointConfig,
  IPermissionEntity
> {
  protected readonly endpoint = 'permission';
}
