import { createEntityAdapter, EntityAdapter } from '@locumsnest/core/src/lib/ngrx/cached-state';
import { IPermissionEntity } from '../../interfaces/api/permission-entity';
import {
  createPaginatedStateAdapter,
  MessageableFactory,
  SignalableFactory,
} from '@locumsnest/core/src/';
import { createLoadingStateAdapter } from '@locumsnest/core/src/lib/adapters/loading-state-adapter';

export function sortByName(a: IPermissionEntity, b: IPermissionEntity): number {
  return a.id - b.id;
}

export const adapter: EntityAdapter<IPermissionEntity> = createEntityAdapter<IPermissionEntity>({
  sortComparer: sortByName,
});

export const messageableFactory = MessageableFactory.forFeature<'Permission'>('Permission');
export const signalableFactory = SignalableFactory.forFeature<'Permission'>('Permission');
export const paginationAdapter = createPaginatedStateAdapter(
  signalableFactory,
  messageableFactory,
  adapter
);

export const loadingAdapter = createLoadingStateAdapter<'Permission'>(messageableFactory);

export const initialState = adapter.getInitialState({
  // additional entity state properties
  selectedPermissionId: null,
});
