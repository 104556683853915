import { SignalableFactory } from '@locumsnest/core/src/lib/ngrx';

const actionableFactory = SignalableFactory.forFeature<'PermissionEntity'>('PermissionEntity');

export class InitializeCurrentUserPermissionsSignal extends actionableFactory.create<
  'InitializeCurrentUserPermissions',
  Record<string, never>
>('InitializeCurrentUserPermissions') {}

export type PermissionSignals = InitializeCurrentUserPermissionsSignal;
