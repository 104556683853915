import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { get } from 'lodash-es';
import { Observable } from 'rxjs';
import { distinctUntilChanged, filter, first, map, mergeMap, skipUntil } from 'rxjs/operators';

import { PaginatedStateService } from '@locumsnest/core/src';

import { IPermissionEntity } from '../../interfaces/api/permission-entity';
import {
  PermissionPaginationMessages,
  ResetPermissionPaginationMessage,
  UpsertMultipleMessage,
  UpsertPermissionPageMessage,
} from './permission.messages';
import { PermissionPersistenceService } from './permission.persistence.service';
import {
  permissionPaginationSelectors,
  selectAllPermissions,
  selectPermissionEntityState,
} from './permission.selectors';
import { InitializeCurrentUserPermissionsSignal } from './permission.signals';

@Injectable({
  providedIn: 'root',
})
export class PermissionService extends PaginatedStateService<
  IPermissionEntity,
  UpsertPermissionPageMessage,
  ResetPermissionPaginationMessage,
  UpsertMultipleMessage
> {
  private readonly currentUserPermissionsNameSpace = 'currentUserPermissions';
  constructor(
    protected store: Store,
    protected persistenceService: PermissionPersistenceService,
  ) {
    super();
  }

  get paginationMessages() {
    return PermissionPaginationMessages;
  }

  get paginationSelectors() {
    return permissionPaginationSelectors;
  }

  get entityStateSelector() {
    return selectPermissionEntityState;
  }

  get upsertMultipleMessage() {
    return UpsertMultipleMessage;
  }

  getAll() {
    return this.store.pipe(select(selectAllPermissions));
  }

  // @todo move to selector
  // @todo sort out current permission filtering
  // when more data will be loaded
  hasCurrentPermission(permission: string): Observable<boolean> {
    return this.getConsecutivePageEntities(this.currentUserPermissionsNameSpace).pipe(
      skipUntil(
        this.isLoaded(this.currentUserPermissionsNameSpace).pipe(filter((isLoaded) => isLoaded)),
      ),
      map(
        (permissions) =>
          !!permissions.find(
            (currentPermission) => get(currentPermission, 'codename') === permission,
          ),
      ),
      distinctUntilChanged(),
      first(),
    );
  }

  fetch() {
    return this.isPendingOrLoaded(this.currentUserPermissionsNameSpace).pipe(
      first(),
      filter((isPendingOrLoaded) => isPendingOrLoaded === false),
      mergeMap(() =>
        this.loadAllPages(
          this.currentUserPermissionsNameSpace,
          { controllerResource: 'permissionCurrent', skipSerializer: true },
          { pageSize: 500 },
        ),
      ),
    );
  }

  initializeCurrentUserPermissions() {
    this.store.dispatch(new InitializeCurrentUserPermissionsSignal({}));
  }
}
